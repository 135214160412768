<template>
  <div class="common-bg">
    <van-nav-bar
      title="公司介绍"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="van-padded-15">
      <div class="van-content" v-html="data.content"></div>
    </div>
  </div>
</template>

<script>
import { getDataByKey } from '@/utils/api.js'
export default {
  name: 'intro',
  data () {
    return {
      data: ''
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      getDataByKey('intro').then(res => {
        this.data = res.data
      })
    },
    onClickLeft () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>

</style>
